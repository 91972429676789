import React, {useEffect, useCallback, useState} from "react";
import {useForm} from 'react-hook-form';
import {useTranslation} from "react-i18next";
import {Button, Card, Col, Form, Row, ListGroup} from "react-bootstrap";
import Slider, {SliderTooltip} from 'rc-slider';
import 'rc-slider/assets/index.css';
import Select from "react-select";
import Creatable from "react-select/creatable";
import {yupResolver} from '@hookform/resolvers/yup';
import * as yup from 'yup';
import LoadingPage from "@/components/LoadingPage";
import {useAuth} from "@/services";
import PlacesAutocomplete from "@/components/Inputs/PlacesAutocomplete";


const {createSliderWithTooltip} = Slider;
const Range = createSliderWithTooltip(Slider.Range);
const {Handle} = Slider;


const handle = props => {
    const {value, dragging, index, ...restProps} = props;
    return (
        <SliderTooltip
            prefixCls="rc-slider-tooltip"
            overlay={`${value}`}
            visible={dragging}
            placement="top"
            key={index}
        >
            <Handle value={value} {...restProps} />
        </SliderTooltip>
    );
};

const SiteFilter = ({options, enableDuration, listView, onlySales, ...props}) => {

    const {t, i18n} = useTranslation();
    const onSubmit = props.onSubmit || (() => {
    });

    const onClose = props.onClose || (() => {
    });

    const schema = yup.object().shape({
        facilities: yup.array().nullable(),
        security_facilities: yup.array().nullable(),
    }).required();


    const [selected, setSelected] = useState(null)

    const {
        handleSubmit,
        register,
        reset,
        watch,
        setValue
    } = useForm({
        defaultValues: {
            security_rating: 4,
            max_distance: 5,
            price_range: [null, null],
            only_sales: onlySales ? 1 : '',
            ...props.values
        },
        resolver: yupResolver(schema),
    });

    const resetForm = () => {
        reset({
            site_size: '',
            max_journey_duration: '',
            booking_method: '',
            facilities: [],
            security_facilities: [],
            address: null,
            security_rating: '',
            only_sales: '',
            location_coordinates: null,
            location_address: null,
            max_distance: 5,
            price_range: [null, null],
        }, {
            keepErrors: true,
            keepDirty: true,
            keepIsSubmitted: false,
            keepTouched: false,
            keepIsValid: false,
            keepSubmitCount: false,
        });
    }

    useEffect(() => {
        register('price_range');
        register('security_rating');
        register('location_coordinates');
        register('location_address');
        register('max_distance');
    }, []);

    const securityRating = watch('security_rating');
    const maxDistance = watch('max_distance');
    const priceRange = watch('price_range');

    const setSelectionCoordinates = (value) => {
      setSelected(value);
      setValue('location_coordinates', value);
    }

    const setSelectionAddress = (value) => {
      setValue('location_address', value);
      setValue('location_address_search', null);
    }

    return (
        <Form onSubmit={handleSubmit(onSubmit)} className="filter p-3 p-md-0">
            {listView &&
                <Row>
                    <Col className="col-12">
                        <Form.Label>
                            {t('dashboard.driver.search_site')}
                        </Form.Label>
                        <PlacesAutocomplete
                            setSelected={setSelectionCoordinates}
                            setAddress={setSelectionAddress}
                            address={props.values?.location_address}
                            listView={listView}
                        />
                    </Col>
                </Row>
            }
            <Row className="mt-3">

                {!listView &&
                    <>
                        <Col sm={6} md={4} lg={3} className="col-12">
                            <Form.Group className="mb-3">
                                <Form.Label>
                                    {t('dashboard.driver.search_site')}
                                </Form.Label>
                                <PlacesAutocomplete
                                    setSelected={setSelectionCoordinates}
                                    setAddress={setSelectionAddress}
                                    address={props.values?.location_address}
                                    listView={listView}
                                />
                            </Form.Group>
                        </Col>
                    </>
                }
                <Col sm={6} md={4} lg={3} className="col-12">
                    <MaxDistance maxDistance={maxDistance} setValue={setValue} handle={handle}/>
                </Col>

                {enableDuration && (
                    <Col sm={6} md={4} lg={3} className="col-12">
                        <Form.Group className="mb-3">
                            <Form.Label>
                                {t('max_journey_duration')}
                            </Form.Label>

                            <Form.Select className="react-select" type="number" aria-label={t('max_journey_duration')}
                                         {...register('max_journey_duration')}>
                                <option value="">{t('filters_labels.any')}</option>
                                <option value={3600}>{t('up_to_one_hour')}</option>
                                <option value={3600 * 2}>{t('up_to_hours', {hours: 2})}</option>
                                <option value={3600 * 3}>{t('up_to_hours', {hours: 3})}</option>
                                <option value={3600 * 4}>{t('up_to_hours', {hours: 4})}</option>
                                <option value={3600 * 5}>{t('up_to_hours', {hours: 5})}</option>
                                <option value={3600 * 6}>{t('up_to_hours', {hours: 6})}</option>
                                <option value={3600 * 7}>{t('up_to_hours', {hours: 7})}</option>
                                <option value={3600 * 8}>{t('up_to_hours', {hours: 8})}</option>
                                <option value={3600 * 9}>{t('up_to_hours', {hours: 9})}</option>
                            </Form.Select>
                        </Form.Group>
                    </Col>
                )}

                <Col sm={6} md={4} lg={3} className="col-12">
                    <Form.Group className="mb-3">
                        <Form.Label>
                            {t('only_show_sales')}
                        </Form.Label>

                        <Form.Select className="react-select"
                                     aria-label={t('only_show_sales')} {...register('only_sales')}>
                            <option value="">{t('show_all')}</option>
                            <option value={1}>{t('show_only')}</option>
                        </Form.Select>
                    </Form.Group>
                </Col>
                <Col sm={6} md={4} lg={3} className="col-12">
                    <Form.Label>
                        {t('postcode_town')}
                    </Form.Label>

                    <Form.Control
                      placeholder={t('enter_postcode_town')}
                      {...register('address')}
                    />

                </Col>
                <Col sm={6} md={4} lg={3} className="col-12">
                    <Form.Group className="mb-3">
                        <Form.Label column className="col-12">
                            {t('price_range')} {priceRange !== 0 ? t('price_range_between', {
                            min: priceRange[0] && priceRange[0].toFixed(2) || t('filters_labels.no_min'),
                            max: priceRange[1] && priceRange[1].toFixed(2) || t('filters_labels.no_max')
                        }) : null}
                        </Form.Label>


                        <Range min={0} max={150} defaultValue={(() => {
                            const value = [0, 150];

                            if (priceRange && priceRange[0]) {
                                value[0] = priceRange[0];
                            }

                            if (priceRange && priceRange[1]) {
                                value[1] = priceRange[1];
                            }

                            return value;
                        })()}
                               tipFormatter={value => value <= 0 && t('filters_labels.no_min') || value >= 150 && t('filters_labels.no_max') || value && `${value.toFixed(2)}`}
                               onAfterChange={(price_range) => {
                                   const value = [null, null];

                                   if (price_range && price_range[0] > 0) {
                                       value[0] = price_range[0];
                                   }

                                   if (price_range && price_range[1] < 150) {
                                       value[1] = price_range[1];
                                   }

                                   setValue('price_range', value);
                               }}/>
                    </Form.Group>
                </Col>
            </Row>

            <Row>
                <Col sm={6} md={4} lg={3} className="col-12">
                    <Form.Group className="mb-3">
                        <Form.Label>
                            {t('site_size')}
                        </Form.Label>

                        <Form.Select className="react-select" aria-label={t('site_size')} {...register('site_size')}>
                            <option value="">{t('filters_labels.any')}</option>
                            <option value="100">100</option>
                            <option value="200">200</option>
                            <option value="300">300</option>
                            <option value="400">400</option>
                            <option value="500">500</option>
                            <option value="> 500">{t('more_than_500_spaces')}</option>
                        </Form.Select>
                    </Form.Group>
                </Col>

                <Col sm={6} md={4} lg={3} className="col-12">
                    <Form.Group className="mb-3">
                        <Form.Label>
                            {t('booking.methods')}
                        </Form.Label>

                        <Form.Select className="react-select"
                                     aria-label={t('booking.methods')} {...register('booking_method')}>
                            <option value="all">{t('show_all')}</option>
                            <option value="instant">{t('booking.instant')}</option>
                            <option value="auto">{t('booking.auto')}</option>
                            <option value="manual">{t('booking.manual')}</option>
                        </Form.Select>
                    </Form.Group>
                </Col>

                <Col sm={6} md={4} lg={3} className="col-12">
                    <Form.Group className="mb-2 mt-2">
                        <Form.Label className="mb-2 mt-1">
                            {t('security_rating')} - {securityRating >= 4 ? t('uncertified') : (securityRating == 1 ? '1 (' + t('best') + ')' : securityRating)}
                        </Form.Label>

                        <Slider reverse={true} min={1} max={4} defaultValue={securityRating}
                                onAfterChange={(e) => setValue('security_rating', e)} handle={handle}/>
                    </Form.Group>
                </Col>


            </Row>

            <h6 className="mt-2">
                {t('facilities')}
            </h6>

            <input
                {...register('facilities[]')}
                value={0}
                className="d-none"
                type="checkbox"
            />

            <Row className="mb-3">
                {options.facilities.map((facility, index) => (
                    <Col xs={6} md={4} xl={3} xxl={2} key={index} className="flex-grow-0">
                        <div className="form-check me-2">
                            <label className="form-check-label text-xl-nowrap">
                                <input
                                    {...register('facilities[]')}
                                    value={facility.id}
                                    className="form-check-input horizontal-check-labels d-flex mb-2"
                                    type="checkbox"
                                />
                                {facility.name}
                            </label>
                        </div>
                    </Col>
                ))}
            </Row>

            <h6 className="mt-2">
                {t('security_facilities')}
            </h6>

            <input
                {...register('security_facilities[]')}
                value={0}
                className="d-none"
                type="checkbox"
            />

            <Row className="mb-3">
                {options.security_facilities.map((facility, index) => (
                    <Col xs={12} md={6} xl={4} xxl={3} key={index} className="flex-grow-0">
                        <div className="form-check me-2">
                            <label className="form-check-label">
                                <input
                                    {...register('security_facilities[]')}
                                    value={facility.id}
                                    className="form-check-input horizontal-check-labels d-flex mb-2"
                                    type="checkbox"
                                />
                                {facility.name}
                            </label>
                        </div>
                    </Col>
                ))}
            </Row>

            <Row className="mb-md-3">
                <Col sm={6} md={4} lg={3} className="col-12">
                    <Form.Group className="mb-2">
                        <Button type="submit" variant="success" className="w-100">
                            {t('search')}
                        </Button>
                    </Form.Group>
                </Col>

                <Col sm={6} md={4} lg={3} className="col-12">
                    <Form.Group className="mb-2">
                        <Button type="button" variant="outline-primary" className="w-100" onClick={() => resetForm()}>
                            {t('remove_all_filters')}
                        </Button>
                    </Form.Group>
                </Col>

                <Col sm={6} md={4} lg={3} className="col-12 d-lg-none">
                    <Form.Group className="mb-2">
                        <Button type="button" variant="outline-primary" className="w-100" onClick={() => onClose()}>
                            {t('close')}
                        </Button>
                    </Form.Group>
                </Col>
            </Row>


        </Form>
    );
};



const MaxDistance = ({maxDistance, setValue, handle}) => {
    const {t, i18n} = useTranslation();
    let auth = useAuth();
    return (
        <Form.Group className="mb-2 mt-2">
            <Form.Label className="mb-2 mt-1">
                {t('max_distance')} - {maxDistance} {auth.user?.distance_units === 'mi' ? 'mi' : 'km'}
            </Form.Label>

            <Slider
                step={5}
                min={5}
                max={200}
                defaultValue={maxDistance}
                onAfterChange={(e) => setValue('max_distance', e)}
                handle={handle}
            />
        </Form.Group>
    )
}

export default SiteFilter;
