import React, {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {useAuth} from "@/services";
import LoadingPage from "@/components/LoadingPage";
import {Button, Card, Col, Form, Row, Spinner} from "react-bootstrap";
import * as moment from "moment-timezone";
import {useHistory} from "react-router-dom";
import MapIcon from "@/assets/images/map-icon.png";
import PayIcon from "@/assets/images/pay-icon-2.png";
import {parseCoordinate} from "../../../../services/Sites";
import SiteCard from "../../../../components/SiteCard";
import PlacesAutocomplete from "../../../../components/Inputs/PlacesAutocomplete";

function LoadingComponent() {
    return (
        <div className="d-flex justify-content-center align-items-center h-25">
            <Spinner animation="border" role="status" variant="primary" size="lg">
                <span className="visually-hidden">Loading...</span>
            </Spinner>
        </div>
    )
}

function DriverDashboard() {

    const {t} = useTranslation();
    const history = useHistory();
    const auth = useAuth();
    const getRequest = auth.getRequest;

    const [loading, setLoading] = useState(true);
    const [sites, setSites] = useState([]);
    const [searchQuery, setSearchQuery] = useState('');
    const [booking, setBooking] = useState([]);
    const [currentLocation, setCurrentLocation] = useState(null);
    const [promotion, setPromotion] = useState('');

    const getData = () => {
        console.log("called waiting for location");
        navigator.geolocation.getCurrentPosition((position) => {
            setCurrentLocation(parseCoordinate(position.coords));
            requestData(`?latitude=${position.coords.latitude}&longitude=${position.coords.longitude}`);
        }, () => {
            requestData();
        }, {
            enableHighAccuracy: false,
            maximumAge: 300000,
            timeout: 5000,
        });
    }

    const requestData = (query = '') => {
        getRequest('/dashboard/driver' + query)
            .then(response => {
                if (response.data.message === 'OK') {
                    setBooking(response.data.booking);
                    setPromotion(response.data.promotion);
                    setSites(response.data.sites);
                    setLoading(false);
                }
            })
            .catch(_ => {
                setLoading(false);
            })
    }

    const search = () => {
        history.push('/find-a-site', {
            query: {
                location_address_search: searchQuery,
                max_distance: 50,
            },
            isList: true,
        });
    }

    const findPromotionSite = (promotion) => {
        if (promotion && promotion.site_id) {
            history.push(`/bookings/site-card/${promotion.site_id}`);
            return;
        } else if (promotion.category_id) {
            history.push(`/find-a-site`, {query: {service_categories: [promotion.category_id]}});
            return;
        }

        history.push(`/find-a-site`)
    }

    useEffect(() => {
        getData()
    }, []);

    return (
        <div className="site-find-card position-relative">

            {loading ? (
                <LoadingComponent/>
            ) : (
                <UpcomingBooking booking={booking}/>
            )}

            {
                (promotion != null && promotion !== '' && typeof promotion === 'object') &&
                    <Row>
                        <Col>
                            <div className="alert alert-success" role="alert">
                                <h5 className="alert-heading"> {promotion.message}</h5>
                                <hr/>
                                <div className="d-grid gap-2">
                                    <button
                                        onClick={()=> {findPromotionSite(promotion)}}
                                        className="btn-light btn">
                                        {t('find_site')}
                                    </button>
                                </div>
                            </div>
                        </Col>
                    </Row>
            }

            <div className="my-2">
                <Row>
                    <div className="mx-sm-2 mx-0">
                        <h3 className="text-primary">{t('dashboard.driver.new_booking')}</h3>
                    </div>
                </Row>
                <Row>
                    <Col>
                        <CardOption
                            image={MapIcon}
                            title={`dashboard.driver.view_map`}
                            link={`/find-a-site`}
                            color={`success`}
                        />
                    </Col>
                    <Col>
                        <CardOption
                            image={PayIcon}
                            title={`dashboard.driver.pay_m_park`}
                            link={`/instant-booking/generate-qr`}
                            color={`primary`}
                        />
                    </Col>
                </Row>

                <Row>
                    <PlacesAutocomplete
                        listView={true}
                        setAddress={setSearchQuery}
                        action={search }
                    />
                </Row>
            </div>

            {loading ? (
                <LoadingComponent/>
            ) : (
                <div className="bg-gray-100 mt-4 px-2 py-3">
                    <h3 className="text-primary">{t('dashboard.driver.nearest_sites')}</h3>
                    {sites.map(site => (
                        <SiteCard site={site} currentLocation={currentLocation}/>
                    ))}
                </div>
            )}

        </div>
    )
}

function UpcomingBooking({booking}) {
    const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    const {t} = useTranslation();
    const history = useHistory();

    if (booking) {
        const fromDate = moment.utc(booking.from_datetime).tz(timezone);
        return (
            <>
                <div className="mx-sm-2 mx-0">
                    <h3 className="text-primary">{t('dashboard.driver.upcoming_bookings')}</h3>
                </div>
                <Card
                    onClick={() => history.push(`/bookings/${booking?.id}`)}
                    style={{cursor: "pointer"}}
                    className="card bg-success text-light small mb-3 p-3 p-xl-3"
                >
                    <Card.Body className="p-0 position-relative">
                        <div className="d-flex justify-content-between align-items-center">
                            <h6 className="mb-0">{booking.site.name}</h6>
                            {booking.site.distance && <p className="small mb-0">{`${booking.site.distance}mi`}</p>}
                        </div>
                        <p className="small mt-2">{booking.site.address}</p>
                        {booking.from_datetime &&
                            <p className="small mb-0">{`${fromDate.format(t('formats.short_datetime_reverse'))}`}</p>
                        }
                        {!booking.from_datetime &&
                            <p className="small mb-0">Current Booking</p>
                        }
                    </Card.Body>
                </Card>
            </>
        );
    }

    return (
        <></>
    );
}

function CardOption({image, link, color, title}) {
    const {t} = useTranslation();
    const history = useHistory();

    return (
        <Card
            onClick={() => history.push(link)}
            style={{cursor: "pointer"}}
            className={`card bg-${color} text-light small mb-3 p-3 p-xl-3`}
        >
            <Card.Body className="p-0 position-relative d-inline-flex justify-content-center align-content-center">
                <img src={image} className="img-fluid p-2" style={{"width": "100px", "height": "100px"}} alt="image"/>
                <h6
                    className="mb-0 position-absolute small"
                    style={{top: "50%", transform: "translate(0%, -50%)",}}
                >{t(title)}</h6>
            </Card.Body>
        </Card>
    );
}

export default DriverDashboard;
